.flex {
    display: grid;
    place-items: center;
}

.image {
    width: auto;
    height: auto;
    max-width: 100%;
    margin: 1em 0 2em 0;
    padding: 0;
}

.switch {
    width: 0px;
    height: 0px;
    position: relative;
}