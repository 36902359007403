.main {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
}

.image {
    margin: 1em;
    width: 40%;
    border: none;
}

.details {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.item {
    margin: 2em 0;
}   

.name {
    margin-bottom: 0;
}

@media only screen and (min-width: 800px) {
    .main {
       flex-direction: row;
    }

    .image {
        width: 50%;
    }
}