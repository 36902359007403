.main {
    text-decoration: none;
    margin: 1em 0 0 0;
    color: var(--darkAccentColor)
}

.sub {
    margin: 0.5em 0 0 0;
    text-decoration: none;
}
