.head, .main, .foot {
    width: 90%;
    margin: 0 auto;
}

.foot {
    margin-bottom: 100px;
}

@media only screen and (min-width: 600px) {
    .head, .main, .foot {
        width: 90%;
    }
}

@media only screen and (min-width: 800px) {
    .head, .main, .foot {
        width: 80%;
    }
}

@media only screen and (min-width: 1000px) {
    .head, .main, .foot {
        width: 70%;
    }
}

@media only screen and (min-width: 1400px) {
    .head, .main, .foot {
        width: 60%;
    }
}