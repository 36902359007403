.header {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
}

.hr {
    background-color: var(--accentColor);
    height: 5px;
    border: none;
}

.title {
    width: 400px;
    height: auto;
}

.list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 50%;
}

.link {
    margin: 0 1em;
    color: var(--accentColor);
    font-size: 1.5em;
}

.headline {
    color: var(--primaryColor);
    text-align: center;
}

@media only screen and (min-width: 1028px) {
    .header {
       flex-direction: row;
    }

    .list {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin: 0 1em;
        width: 33%
    }
}