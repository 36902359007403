.button {
    margin: 10px;
    border: none;
    cursor: pointer;
    background-color: var(--backgroundColor);
}

.dark {
    background-color: var(--textColor);
}

.image {
    width: 40px;
    height: auto;
}