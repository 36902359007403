.main {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 auto;
    justify-content: space-evenly;
}

.image {
    margin: 1em;
    width: 40%;
    border: none;
}

.summary {
    margin: 1em 0;
    white-space: pre-line;
}

@media only screen and (max-width: 800px) {
    .main {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

    .image {
        width: 80%;
    }
}