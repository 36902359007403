.main {
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
    margin: var(--margin) 0;
}

.main > * {
    margin: 0 calc(var(--margin) / 2);
}