.button {
    width: var(--lightSwitch);
    height: var(--lightSwitch);
    border: none;
    cursor: pointer;
    background-color: transparent;
}

.button:focus {
    outline: none;
}