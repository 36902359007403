:root {
  /* colours */
  --accentColor: #edcf0e; 
  --backgroundColor: #f5f5f5;
  --darkAccentColor: #78ad82;
  --middleGrey: #999999;
  --primaryColor: #808080;
  --textColor: #202020;
}

:root {
  /* vars */
  --lightSwitch: 30px;
  --margin: 10px;
}

@import url('https://fonts.googleapis.com/css2?family=Alata&family=Dosis');

body, html {
  max-width: 100vw;
  margin: 0 auto;
  overflow-x: hidden;
}

body {
  margin: 0 auto;
  padding: 0 0 5% 0;
  background-color: var(--middleGrey);
  color: var(--textColor);
  font-family: 'Dosis', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  transition: background-color 0.2s linear;
}

body[data-theme='light'] {
  background-color: var(--backgroundColor);
  color: var(--textColor);
}

body[data-theme='dark'] {
  background-color: var(--textColor);
  color: var(--backgroundColor);
}

a, a:visited, a:hover {
  font-family: 'Alata', sans-serif;
  color: var(--primaryColor)
}
