.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

.image {
    margin: 1em 0;
    max-width: 100%;
    width: auto;
    height: auto;
    border: none;
}

.title {
    max-width: 90%;
    text-align: center;
}