.link {
    margin: 20px;
    height: 50px;
    text-align: center;
}

.projects {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.thumb {
    --sideMargin: 17%;
    list-style-type: none;
    margin-bottom: 2em;
    width: 32%;
}

.thumb:nth-last-of-type(2) {
    margin-left: var(--sideMargin);
}

.thumb:nth-last-of-type(1) {
    margin-right: var(--sideMargin);
}

@media only screen and (max-width: 800px) {
    .thumb {
        --sideMargin: 0;
        width: 48%;
    }
}